export default {
    news: [ // 공지사항 목록
        {
            'id': '3',
            'thumb' : require('@/assets/img/news-img-03.jpg'),
            'title': '네트러닝홀딩스 한국지사 5주년',
            'content': '네트러닝홀딩스 한국지사가 설립 5주년을 맞이했습니다. 한국지사는 최근 5년간 크게 발전하여 많은 업적을 올려 왔습니다. 특히 오픈 배지 플랫폼 개발을 비롯해 시스템 개발에 많은 사회적 공헌도 하고 있습니다. 관련하여 2년 전에는 LecoS(한국)도 설립되어 한국에서의 네트러닝 홀딩스의 사업도 착실하게 발전해 왔습니다.'
        },
        {
            'id': '2',
            'thumb' : require('@/assets/img/news-img-01.jpg'),
            'title': '㈜레코스와 네트러닝 그룹은 제19회 대한민국 교육박람회에 참가합니다.',
            'content': '㈜레코스와 네트러닝 그룹(회장 키시다 토오루)이 2022년 4월 26일-28일에 코엑스에서 개최되는 제19회 대한민국 교육박람회에 참가합니다. 본 행사는 11개국 225개사가 620부스 규모로 참가한 2021년에는 30,947명이 참관한 명실상부 대한민국 최대의 전시회로 평가받고 있으며 구글, 마이크로소프트, 레고, 국가평생교육진흥원, EBS등이 참가하는 글로벌 행사입니다.'
        },
        {
            'id': '1',
            'thumb' : require('@/assets/img/news-img-02.jpg'),
            'title': 'AES(Asia Edtsch Summit)는 제3회 AES GLOBAL AWARD를 시상합니다.',
            'content': 'AES(Asia Edtech Summit)는 매년 11월 일본 도쿄에서 개최되는 이러닝어워드 재팬 개최 기간 동안 제3회 AES GLOBAL AWARD를 시상합니다. 한,중,일의 에듀테크 기업들의 상생 발전을 도모하기 위해 결성된 AES(의장 키시다 토오루 일본온라인교육산업협회 회장)는 2019년 한국에듀테크산업협회(이길호 회장), 중국 리테크 그룹(아이숭광 의장)과 네트러닝 그룹이 중심이 되어 발족되었으며 현재 80여개 아시아 에듀테크 기업들이 참가하고 있는 글로벌 경제 협력체입니다.'
        }
    ],
    newsDetail: [ // 공지사항 상세
        {
            'id': '3',
            'thumb' : require('@/assets/img/news-img-big-03.jpg'),
            'title': '네트러닝홀딩스 한국지사 5주년',
            'content': '네트러닝홀딩스 한국지사가 설립 5주년을 맞이했습니다.<br /><br />한국지사는 최근 5년간 크게 발전하여 많은 업적을 올려 왔습니다. 특히 오픈 배지 플랫폼 개발을 비롯해 시스템 개발에 많은 사회적 공헌도 하고 있습니다. 관련하여 2년 전에는 LecoS(한국)도 설립되어 한국에서의 네트러닝 홀딩스의 사업도 착실하게 발전해 왔습니다. 이것은 사사키 키미히로 초대 지사장, 키시다 스스무 2대 지사장, 노원석 현 지사장을 시작으로 지사 사원 전원이 힘을 합쳐 노력해 온 결과입니다.<br /><br />네트러닝홀딩스 한국지사의 5주년은 다음 5년을 향한 시작입니다. 특별히 2024년은 네트러닝 그룹이 창업 25주년을 맞이하는 해이기도 합니다. 네트러닝 그룹은 한국과 아시아에서 각국의 에듀테크 기업들과 함께 ASIA EDTECH SUMMIT 활동을 포함하여 아시아 에듀테크 산업의 상생과 발전을 위해 노력해 나갈 것입니다. 다시 한번 지난 5년간 함께 해 주신 모든 분들께 진심으로 감사의 인사를 전합니다. 우리 모두 “배움을 통해 미래를 개척”하는 네트러닝 그룹과 함께 더욱 크게 도약해 나아가길 희망해 봅니다. 감사합니다.<br /><br /><div style="text-align: center;">2023년 11월 1일</div><div style="text-align: right;">네트러닝홀딩스 이사회 의장 <br />키시다 토오루</div>'
        },
        {
            'id': '2',
            'thumb' : require('@/assets/img/news-img-big-01.jpg'),
            'title': '㈜레코스와 네트러닝 그룹은 제19회 대한민국 교육박람회에 참가합니다.',
            'content': '㈜레코스와 네트러닝 그룹(회장 키시다 토오루)이 2022년 4월 26일-28일에 코엑스에서 개최되는 제19회 대한민국 교육박람회에 참가합니다. 본 행사는 11개국 225개사가 620부스 규모로 참가한 2021년에는 30,947명이 참관한 명실상부 대한민국 최대의 전시회로 평가받고 있으며 구글, 마이크로소프트, 레고, 국가평생교육진흥원, EBS등이 참가하는 글로벌 행사입니다. <br /><br />올해는 네트러닝 그룹의 계열사인 ㈜레코스가 오픈배지(IMS GLOBAL 국제표준 디지털 인증)의 다양한 활용을 주제로 전시를 계획하고 있으며 박람회 사무국과 함께 온라인 사전 등록자와 EDUCON 참석자들에게 오픈배지(참가인증)를 발급할 예정입니다. <br /><br />오픈배지와 관련한 자세한 정보는 www.lecos.co.kr을 참고하시기 바라며 대한민국교육박람회의 전체 일정과 세부사항은 http://www.educationkorea.kr/에서 확인하실 수 있습니다.'
        },
        {
            'id': '1',
            'thumb' : require('@/assets/img/news-img-big-02.jpg'),
            'title': 'AES(Asia Edtsch Summit)는 제3회 AES GLOBAL AWARD를 시상합니다.',
            'content': 'AES(Asia Edtech Summit)는 매년 11월 일본 도쿄에서 개최되는 이러닝어워드 재팬 개최 기간 동안 제3회 AES GLOBAL AWARD를 시상합니다. 한,중,일의 에듀테크 기업들의 상생 발전을 도모하기 위해 결성된 AES(의장 키시다 토오루 일본온라인교육산업협회 회장)는 2019년 한국에듀테크산업협회(이길호 회장), 중국 리테크 그룹(아이숭광 의장)과 네트러닝 그룹이 중심이 되어 발족되었으며 현재 80여개 아시아 에듀테크 기업들이 참가하고 있는 글로벌 경제 협력체입니다. <br /><br />AES GLOBAL AWARD는 아시아 에듀테크 기업중 우수 제품과 서비스를 제공하는 기업 가운데 엄격한 심사를 거쳐 해마다 플래티넘, 골드, 실버, 브론즈 수상자를 선정, 발표하고 있습니다. 제1회 수상은 글로벌 기업인 CISCO와 한국의 아이포트폴리오, 제2회는 일본의 베네쎄 그룹과 한국의 윤선생(현대영어사)이 수상한 바 있습니다. 제3회 시상 응모는 8월말까지이며 자세한 사항은 AES 홈페이지(https://aesglobal.org/) 또는 수상 주관단체인 이러닝어워드 재팬 홈페이지(https://www.elearningawards.jp/)를 참고하시기 바랍니다.'
        }
    ],
    press: [ // 언론보도 목록
        {
            'id': '17',
            'newsroom': '전자신문',
            'title': '2024 에듀테크 코리아 페어 개막',
            'link': 'https://www.etnews.com/20240923000289',
            'date': '2024-09-23'
        },
        {
            'id': '16',
            'newsroom': '전자신문',
            'title': '[에듀플러스]키시다 토오루 日 네트러닝 회장 “韓, 아시아에서 에듀테크 가장 발전하고 있는 곳…IT·AI 기술 활용해 맞춤형 교육 제공해야”',
            'link': 'https://n.news.naver.com/mnews/article/030/0003203232?sid=105',
            'date': '2024-05-02'
        },
        {
            'id': '15',
            'newsroom': 'Daum 헤럴드경제',
            'title': '성공회대, 오는 30일 ‘개교 110주년 기념식’ 개최',
            'link': 'https://v.daum.net/v/20240427060113129',
            'date': '2024-04-27'
        },
        {
            'id': '14',
            'newsroom': '전자신문',
            'title': '한국 에듀테크 기업, 글로벌 에듀테크 어워드 휩쓸어',
            'link': 'https://www.etnews.com/20231030000179',
            'date': '2023-10-30'
        },
        {
            'id': '13',
            'newsroom': 'UNN',
            'title': '싸이웍스, 日 ‘2023 AES GLOBAL AWARD’ 최고 대상 수상',
            'link': 'https://news.unn.net/news/articleView.html?idxno=554505',
            'date': '2023-10-30'
        },
        {
            'id': '12',
            'newsroom': '전자신문',
            'title': '한·일 AES 글로벌 간담회 개최...아시아 에듀테크 협력 방안 논의',
            'link': 'https://www.etnews.com/20230420000164',
            'date': '2023-04-20'
        }, 
        {
            'id': '11',
            'newsroom': 'UNN',
            'title': '메타버스에 올라탄 64개 전문대, 지난 2년 발자취…양적 성장 넘어 ‘질적 도약’ 이룬다',
            'link': 'https://news.unn.net/news/articleView.html?idxno=539227',
            'date': '2022-12-22'
        },    
        {
            'id': '10',
            'newsroom': '전자신문',
            'title': '[人 사이트]키시다 토오루 日 네트러닝 회장 "한국 에듀테크 기업과 글로벌 공동 진출"',
            'link': 'https://m.etnews.com/20220927000185?obj=Tzo4OiJzdGRDbGFzcyI6Mjp7czo3OiJyZWZlcmVyIjtOO3M6NzoiZm9yd2FyZCI7czoxMzoid2ViIHRvIG1vYmlsZSI7fQ%3D%3D',
            'date': '2022-09-27'
        },
        {
            'id': '9',
            'newsroom': '신아일보',
            'title': '윤선생, 일본 네트러닝홀딩스와 글로벌 교육시장 동반진출 맞손',
            'link': 'http://www.shinailbo.co.kr/news/articleView.html?idxno=1602924',
            'date': '2022-09-26'
        },
        {
            'id': '8',
            'newsroom': '전자신문',
            'title': 'AES(Asia Edtech Summit) 공식 창립식',
            'link': 'https://m.etnews.com/20220922000169',
            'date': '2022-09-22'
        },
        {
            'id': '7',
            'newsroom': '성공회대학교',
            'title': '일본기업 ㈜네트러닝홀딩스와 MOU 체결',
            'link': 'https://www.skhu.ac.kr/bbs/skhu/39/51289/artclView.do?layout=unknown',
            'date': '2022-09-22'
        },
        {
            'id': '6',
            'newsroom': 'UNN',
            'title': '한국고등직업교육학회, 네트러닝홀딩스와 디지털배지 기술 도입 MOU 체결',
            'link': 'https://news.unn.net/news/articleView.html?idxno=534197',
            'date': '2022-09-20'
        },
        {
            'id': '5',
            'newsroom': '아웃소싱타임스',
            'title': '일본 네트러닝홀딩스 · 지식콘텐츠기업협회, "오픈배지"와 지식콘텐츠 사업 공동 추진을 위한 업무 협약 체결',
            'link': 'http://www.outsourcing.co.kr/news/articleView.html?idxno=93888',
            'date': '2022-05-02'
        },
        {
            'id': '4',
            'newsroom': 'ETNEWS',
            'title': '한중일 에듀테크 기업 뭉쳐...아시아 에듀테크 써밋 9월 공식 발족 추진',
            'link': 'https://www.etnews.com/20220502000256',
            'date': '2022-05-02'
        },
        {
            'id': '3',
            'newsroom': 'ETNEWS',
            'title': '학력 대신에 학습 경력, "디지털 자격 증명" 부상',
            'link': 'https://www.etnews.com/20220429000237',
            'date': '2022-05-01'
        },
        {
            'id': '2',
            'newsroom': 'ETNEWS',
            'title': '[에듀테크코리아]K-에듀테크 국내 넘어 해외로...유니콘 기업 꿈꿔',
            'link': 'https://www.etnews.com/20210914000155',
            'date': '2021-09-14'
        },
        {
            'id': '1',
            'newsroom': '디지털타임스',
            'title': '[기고] 디지털 플랫폼 학습은 필수다',
            'link': 'http://www.dt.co.kr/contents.html?article_no=2021051702102369660002&ref=naver',
            'date': '2021-05-16'
        }
    ],
};
  
